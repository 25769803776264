import React from 'react';
import TitlePic from '../images/yoga-pose.jpg';


function Index() {
    return (
        <div className="index-body">
            <img src={TitlePic} height={400} width={600} alt="chris performing warrior pose"/>
        </div>
    )
}

export default Index
